
#botonEventoPerzonalizado {
  position: absolute;
  top: -1000px;
}
#botoneraMapaHome {
  top: 0;
  right: -200px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}
#botoneraMapaHome .opcionVehiculo {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.botonesVehiculos {
  position: absolute;
  top: -30px;
  right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.botonesVehiculos .opcionVehiculo {
    height: 30px;
    width: 75px;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
    font-size: 1.5rem;
    background: #808080;
    margin-left: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.botonesVehiculos .opcionVehiculo.activo {
      color: #ffc000;
      background: #383838;
}
.contenidoImagenes {
  position: absolute;
  top: -1000px;
  right: 0;
  z-index: 10;
}
.contenidoImagenes .fondoMarket {
    width: 200px;
    height: 200px;
    position: relative;
}
.contenidoImagenes .fondoMarket .elemento {
      position: absolute;
      top: 20px;
      left: 30px;
      font-size: 2rem;
      color: #ffc000;
}
.contenidoImagenes .lblIndicador {
    position: absolute;
    top: 10px;
    width: 120px;
    left: 60px;
    font-size: 2rem;
    text-align: center;
    height: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 900;
}
.contenidoImagenes .lblDesdeAuto {
    left: 51px;
}
.cuadroControl {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.mapGoogle {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.mapaHome {
  height: 100%;
  background-size: cover;
  background-position: center;
  color: #fff;
}
#imagenHomeMovil img {
  max-width: 100%;
}
#cuadroMapHome {
  background: rgba(60, 122, 209, 0.9);
  border-radius: 15px;
  padding: 15px;
  width: 100%;
  max-width: 350px;
  position: absolute;
  left: 0;
  top: 250px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);
}
.cuadroControl label {
  color: #fff;
  margin-left: 10px;
}
button {
  width: initial;
}
.botonAction {
  width: 250px;
  border: 2px solid #fff;
  padding: 5px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-family: "Roboto";
  font-weight: 500;
  margin: 5px 0;
  letter-spacing: 1px;
  text-overflow: hidden;
  white-space: nowrap;
}
.filaInput {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}
.filaInput span {
    background: #fff;
    color: #666;
    font-weight: 500;
    white-space: nowrap;
    line-height: 40px;
    vertical-align: middle;
    padding-left: 5px;
}
.filaInput input {
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 5px 10px;
}
.filaInput button {
    background: #21597f;
    color: #fff;
    border: 0;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 50px;
}
.filaInput button:hover {
      opacity: .8;
}
.contenidoTooltip {
  color: #499f42;
}
@media (max-width: 576px) {
#botoneraMapaHome {
    padding: 0;
    background: transparent;
    top: 215px;
}
#botoneraMapaHome .opcionVehiculo {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
}
.botonesVehiculos {
    top: 215px;
    right: 0 !important;
    z-index: 8;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.botonesVehiculos .opcionVehiculo {
      height: 50px;
      width: 30px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
      margin-bottom: 5px;
}
#cuadroMapHome {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    padding: 10px;
    position: relative;
    max-width: 100%;
}
.mapGoogle {
    height: 450px;
}
.cuadroControl {
    position: relative;
}
#btnCambiarLocaciones {
    margin-top: -25px;
    margin-right: 15px;
    border: 1px solid #3d81e2;
}
.mapaHome {
    background-image: none !important;
}
.filaInput {
    margin-bottom: 10px;
}
.seccionBuscarRuta label {
    display: none;
}
.seccionBuscarRuta #cuadroMapHome {
    border-radius: 0;
}
.seccionBuscarRuta .botonAction {
    font-size: 1rem;
    margin: 0;
}
.seccionBuscarRuta .container {
    padding: 0;
}
}

.cuadroResultado {
  position: absolute;
  top: 60px;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  color: #fff;
  left: 0;
  text-align: center;
}
.cuadroResultado .indicacionEstaciones {
    background: rgba(79, 140, 227, 0.9);
    border-radius: 10px;
    padding: 5px;
    margin-top: 15px;
    font-size: 1.2rem;
}
.cuadroResultado .soloUnaLinea {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
.cuadroResultado .boxCuadro {
    padding: 0 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.cuadroResultado .borderBottomBlanco {
    border-bottom: 1px solid #fff;
}
.cuadroResultado .borderLeftNegro {
    border-left: 1px solid #333;
    margin-left: 15px;
    padding: 0 15px;
}
.cuadroResultado .borderLeftBlanco {
    border-left: 1px solid #fff;
    margin-left: 15px;
    padding: 0 15px;
}
.cuadroResultado .help {
    padding: 0 15px;
}
.cuadroResultado .fondoCeleste {
    background: rgba(79, 140, 227, 0.9);
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 15px 0 15px 15px;
    line-height: 1rem;
}
.cuadroResultado .fondoAzulNegro {
    background: #21597f;
    font-size: 1.5rem;
}
.cuadroResultado .btnAtras {
    background: #21597f;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 10px;
    white-space: nowrap;
    padding: 5px 10px;
}
.cuadroResultado .cabeceraCuadro {
    background: #499f42;
    margin: 0;
    color: #fff;
    line-height: 1rem;
    padding: 5px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
}
.cuadroResultado .cabeceraCuadro strong {
      font-weight: 900;
      font-size: 2rem;
}
.cuadroResultado .seccionTiempo {
    margin-top: 15px;
    background: #499f42;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 10px;
    padding: 5px 0;
}
.cuadroResultado .sombra {
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 576px) {
.cuadroResultado {
    position: relative;
    top: 0;
}
.cuadroResultado .borderBottomBlanco {
      border-bottom: 0;
}
.cuadroResultado .borderBottomBlanco .fondoAzulNegro {
        border-bottom: 1px solid #fff;
}
.cuadroResultado .borderBottomBlanco .fondoCeleste {
        border-bottom: 1px solid #21597f;
}
.cuadroResultado .indicacionEstaciones {
      background: #fff;
      border-radius: 0;
      color: #000;
      margin-top: 0;
}
.cuadroResultado .btnAtras {
      background: #fff;
      border: 1px solid #333;
      color: #333;
}
.cuadroResultado .cabeceraCuadro {
      margin: 0;
      padding: 5px;
      border-radius: 0;
      font-size: .9rem;
      background: #4f8ce3;
}
.cuadroResultado .cabeceraCuadro strong {
        font-size: 1.6rem;
}
.cuadroResultado .cabeceraCuadro .help {
        text-align: right;
        margin-right: 5px;
        margin-left: 5px;
        padding: 0;
}
.cuadroResultado .cabeceraCuadro .borderLeftNegro {
        padding: 0 0 0 5px;
        margin-left: 5px;
}
.cuadroResultado .fondoCeleste {
      background: #fff;
      color: #333;
      padding: 5px 0 5px 15px;
}
.cuadroResultado .colorAmarillo {
      color: #21597f;
}
.cuadroResultado .boxCuadro {
      border-radius: 0;
}
.cuadroResultado .fondoAzulNegro {
      font-size: 1.4rem;
}
.cuadroResultado .seccionTiempo {
      margin: 0;
      border-radius: 0;
      background: #4f8ce3;
}
}

.vue-map-container {
  position: relative;
}
.vue-map-container .vue-map {
  left: 0; right: 0; top: 0; bottom: 0;
  position: absolute;
}
.vue-map-hidden {
  display: none;
}

.vue-street-view-pano-container {
  position: relative;
}
.vue-street-view-pano-container .vue-street-view-pano {
  left: 0; right: 0; top: 0; bottom: 0;
  position: absolute;
}

.contenidoPreguntas.cabecera {
  max-width: 512px;
  width: calc(100% - 30px);
  margin: 0 auto;
}
.contenidoPreguntas.cabecera .pregunta {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #499f42;
    font-size: 1.5rem;
    padding: 5px 10px;
}
.contenidoPreguntas.cabecera .pregunta:hover {
      color: #fff;
}
#botonEventoPerzonalizado {
  position: absolute;
  top: -1000px;
}
.precargaMapaEstacion {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  text-align: center;
}
.precargaMapaEstacion img path {
    fill: #ffc000;
}
.contenidoImagenes {
  position: absolute;
  top: -1000px;
  right: 0;
  z-index: 10;
}
.contenidoImagenes .fondoMarket {
    width: 200px;
    height: 200px;
    position: relative;
}
.contenidoImagenes .fondoMarket .elemento {
      position: absolute;
      top: 20px;
      left: 30px;
      font-size: 2rem;
      color: #ffc000;
}
.contenidoImagenes .lblIndicador {
    position: absolute;
    top: 10px;
    width: 120px;
    left: 60px;
    font-size: 2rem;
    text-align: center;
    height: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 900;
    color: #fff;
}
.contenidoImagenes .lblDesdeAuto {
    left: 51px;
}
.contenedorMapaEstacion {
  position: relative;
}
.contenedorMapaEstacion .mapGoogle {
    height: 400px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 80%;
  max-width: 650px;
  min-width: 300px;
  text-align: center;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #3d81e2;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: 1px solid #fff;
  color: #fff;
  position: relative;
}
.botonCerrarModal {
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
  color: #fff;
  border: 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 10px;
}
.modal-header h3 {
  margin-top: 0;
  color: #fff;
}
.modal-body {
  margin: 20px 0;
}
.modal-body p {
    font-size: .8rem;
}
.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 576px) {
.modal-container {
    padding: 15px;
}
.modal-header {
    margin-top: 15px;
}
}

.seccionPC {
  padding-top: 5px;
  padding-bottom: 5px;
}
.movilFinalizado {
  color: #194fab !important;
}
.movilIrregular {
  color: #ffc000 !important;
}
.movilCancelado {
  color: #ff0000 !important;
}
.finalizado.titulo {
  color: #194fab !important;
}
.irregular.titulo {
  color: #ffc000 !important;
}
.cancelado.titulo {
  color: #ff0000 !important;
}
.tiempoMovil {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.timer {
  font-weight: bold;
  color: #333;
  white-space: nowrap;
  padding-left: 5px;
  position: relative;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  padding-right: 15px;
  height: 0;
  border-left: 14px solid transparent;
  color: #fff;
}
.timer_normalidad {
    border-bottom: 35px solid #499f42;
}
.timer_irregular {
    border-bottom: 35px solid #ffc000;
}
.timer_cancelado {
    border-bottom: 35px solid #ff0000;
}
.timer_finalizado {
    border-bottom: 35px solid #194fab;
}
.horaMovil {
  font-size: 1.2rem;
  color: #499f42;
  padding: 0 0 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#botonCerrarFranjaMovil {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.franja {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 15px;
  background: #f6ee93;
  color: #000;
}
.franja .limpio {
    background: transparent;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.franja .limpio .texto {
      text-decoration: underline;
}
.franja .derecha {
    padding-left: 15px;
    border-left: 1px solid #000;
    margin-left: 15px;
}
.franja .circulo {
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: .7rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #000;
    margin: 0 10px;
}
@media (max-width: 576px) {
.derecha {
    display: none;
}
}
